

// Add the event listener
if (!window.location.href.includes('course-credits')) {
  function onTabClick(event) {
    const activeTabs = document.querySelectorAll('.add-to-basket-container .active');

    // Deactivate existing active tab and panel
    activeTabs.forEach(function(tab) {
      tab.classList.remove('active');
    });

    // Activate new tab and panel
    const clickedTab = event.target;
    clickedTab.classList.add('active');

    const tabId = clickedTab.getAttribute('data-tab');
    document.getElementById(tabId).classList.add('active');
  }

  const tabElement = document.querySelector('.add-to-basket-container ul');
  if(tabElement){
    tabElement.addEventListener('click', function(event) {
      if (event.target.tagName === 'LI') {
        onTabClick(event);
      }
    }, false);
  }
}

// Setting the height and width of the video on the product pages in the woocommerce gallery
const echoEmbeddedVideo = document.getElementById('echo-vimeo-embedded-video');


if (echoEmbeddedVideo) {
  const embeddedVideoHoldingImage = document.querySelector('.container-lazyload ');
  const lazyLoadDiv = document.querySelector('.lazy-load-div')
  const woocommerceImage = document.querySelectorAll('.woocommerce-product-gallery__image')[0]
  if (woocommerceImage) {
      const viewportHeight = woocommerceImage.clientHeight;
      echoEmbeddedVideo.style.height = viewportHeight + 'px'
      lazyLoadDiv.style.height = viewportHeight + 'px'
      lazyLoadDiv.style.width = 'auto'
    }
    if (embeddedVideoHoldingImage){
      embeddedVideoHoldingImage.addEventListener('click', function() {
        resizeVideo(element)
      })
    }
}

function resizeVideo(domContainerItem) {
  const videoRatio = determineVideoRatio(domContainerItem);
  findElements(
    'object, embed, iframe, .preview-lazyload, .lazy-load-div',
    domContainerItem,
  ).forEach((domItem) => {
    const element = domItem;
    const width = element.parentNode.clientWidth;
    const height = Math.round(width * videoRatio);
    element.setAttribute('height', `${height}px`);
    element.setAttribute('width', `${width}px`);
    element.style.height = `${height}px`;
    element.style.width = `${width}px`;
  });
}

function determineVideoRatio(element) {
  const aspectRatioClass = String(element?.parentNode?.parentNode?.classList).match(/wp-embed-aspect-\d+-\d+/);
  if (aspectRatioClass) {
    const ratioraw = aspectRatioClass[0].replace('wp-embed-aspect-', '');
    const splitratio = ratioraw.split('-');
    const result = Number(splitratio[1]) / Number(splitratio[0]);
    const countDec = result.toString().split('.')[1].length;
    if (countDec > 4) {
      return Math.round(result * 10000) / 10000;
    }
    return result;
  }
  return 0.5625; // <-- default video ratio
}
function findElements(domSelector, rootNode = document) {
  return [].slice.call(rootNode.querySelectorAll(domSelector));
}
