// Right now you can add 1 video per page and this code will work
// Will need to add a loop here if you want to add more than 1 video per page

const echoEmbeddedVideo = document.getElementById('echo-embed-video');


if (echoEmbeddedVideo) {
  const embeddedVideoHoldingImage = document.querySelector('.container-lazyload ');
  const lazyLoadDiv = document.querySelector('.lazy-load-div')
  if (embeddedVideoHoldingImage) {
      const viewportHeight = woocommerceImage.clientHeight;
      echoEmbeddedVideo.style.height = viewportHeight + 'px'
      lazyLoadDiv.style.height = viewportHeight + 'px'
      lazyLoadDiv.style.width = 'auto'
    }
    if (embeddedVideoHoldingImage){
      embeddedVideoHoldingImage.addEventListener('click', function() {
        resizeVideo(element)
      })
    }
}

function resizeVideo(domContainerItem) {
  const videoRatio = determineVideoRatio(domContainerItem);
  findElements(
    'object, embed, iframe, .preview-lazyload, .lazy-load-div',
    domContainerItem,
  ).forEach((domItem) => {
    const element = domItem;
    const width = element.parentNode.clientWidth;
    const height = Math.round(width * videoRatio);
    element.setAttribute('height', `${height}px`);
    element.setAttribute('width', `${width}px`);
    element.style.height = `${height}px`;
    element.style.width = `${width}px`;
  });
}

function determineVideoRatio(element) {
  const aspectRatioClass = String(element?.parentNode?.parentNode?.classList).match(/wp-embed-aspect-\d+-\d+/);
  if (aspectRatioClass) {
    const ratioraw = aspectRatioClass[0].replace('wp-embed-aspect-', '');
    const splitratio = ratioraw.split('-');
    const result = Number(splitratio[1]) / Number(splitratio[0]);
    const countDec = result.toString().split('.')[1].length;
    if (countDec > 4) {
      return Math.round(result * 10000) / 10000;
    }
    return result;
  }
  return 0.5625; // <-- default video ratio
}
function findElements(domSelector, rootNode = document) {
  return [].slice.call(rootNode.querySelectorAll(domSelector));
}



