document.addEventListener('DOMContentLoaded', () => {

   // Handle accordion logic
   const courseRows = document.querySelectorAll('.courses-table tr');
   courseRows.forEach((row) => {
     const moreInfoSpan = row.querySelector('span');
     if (moreInfoSpan) {
       moreInfoSpan.addEventListener('click', () => {
         let accordion = row.querySelector('.accordion-content');
         if (accordion) {
           // Toggle existing accordion
           const isOpen = accordion.style.maxHeight && accordion.style.maxHeight !== "0px";
           accordion.style.maxHeight = isOpen ? "0" : `${accordion.scrollHeight}px`;
           accordion.style.padding = isOpen ? "0" : "1rem";
           moreInfoSpan.classList.toggle('open', !isOpen);
         } else {
           // Create and populate accordion
           accordion = document.createElement('div');
           accordion.classList.add('accordion-content');
           accordion.innerHTML = `
             <div>Accreditation: ${row.querySelector('td:nth-child(2)')?.innerText || 'N/A'}</div>
             <div>Duration: ${row.querySelector('td:nth-child(3)')?.innerText || 'N/A'}</div>
             <div>Credits: ${row.querySelector('td:nth-child(4)')?.innerText || 'N/A'}</div>
           `;
           row.appendChild(accordion);
           accordion.style.maxHeight = `${accordion.scrollHeight}px`;
           accordion.style.padding = "1rem";
           moreInfoSpan.classList.add('open');
         }
       });
     }
   });

   const modal = document.getElementById('all-courses-modal');
   const modalBody = document.getElementById('modal-body');
   const closeButton = modal ? modal.querySelector('.close-btn') : null;
   const submitButton = modal ? modal.querySelector('.submit-btn') : null;

  // Open modal and populate dynamically
  document.addEventListener('click', (e) => {
    if (e.target.matches('.buy-btn button')) {
      const quantity = e.target.closest('tr').querySelector('.quantity input').value;
      const productId = e.target.getAttribute('data-productid');
      const productName = e.target.closest('tr').querySelector('.product-name a').textContent;

      if (quantity == 1) {
        modalBody.innerHTML = `
          <p>Product: ${productName}</p>
          <p>Quantity: ${quantity}</p>
        `;
        setDataOnModal(productId, quantity);
        modal.style.display = 'block'; // Display modal
      } else if (quantity > 1) {
        addItemToCart(productId, quantity, 'team'); // Direct team purchase
      } else {
        alert('Enter a valid quantity');
      }
    }
  });

  // Close modal
  if (closeButton){
    closeButton.addEventListener('click', () => {
      modal.style.display = 'none';
      setDataOnModal();
    });

  }

  // Close modal on outside click
  window.addEventListener('click', (event) => {
    if (event.target === modal) {
      modal.style.display = 'none';
    }
  });

  // Handle modal submission
  if(submitButton){
    submitButton.addEventListener('click', () => {
      submitModal();
    });
  }

  function submitModal() {
    const productId = modalBody.getAttribute('data-productid');
    const quantity = modalBody.getAttribute('data-quantity');
    const buyingForMyself = document.getElementById('buying-for-myself').checked;
    const buyingForOthers = document.getElementById('buying-for-others').checked;

    if (!buyingForMyself && !buyingForOthers) {
      alert('Please select an option.');
      return;
    }

    const purchaseType = buyingForMyself ? 'individual' : 'team';
    addItemToCart(productId, quantity, purchaseType);
    setDataOnModal(); // Reset modal data
  }

  function addItemToCart(productId, quantity, purchaseType) {
    fetch(`/?add-to-cart=${productId}&quantity=${quantity}&purchase_type=${purchaseType}`)
      .then((response) => response.text()) // Parse as text
      .then((data) => {
        displayToastMessage(); // Show toast
      })
      .catch((error) => console.error('Error:', error));

    modal.style.display = 'none'; // Hide modal
  }


  function setDataOnModal(productId = '', quantity = '') {
    modalBody.setAttribute('data-productid', productId);
    modalBody.setAttribute('data-quantity', quantity);
  }

  function displayToastMessage() {
    const toastMessage = document.querySelector('.all-courses-toast-message');
    if (!toastMessage) return;

    toastMessage.classList.add('visible');
    setTimeout(() => {
      toastMessage.classList.remove('visible');
    }, 3000);
  }

});

